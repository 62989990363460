import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import axios from 'axios'

Vue.use(Router)

let router = new Router({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes: [

        {
            name: 'applogin',
            path: '/auth/login',
            props: true,
            meta: {
                requiresAuth: false,
            },
            component: () =>
                import ('./components/commonComponents/adminLogin'),
        },
        {
            name: 'page404',
            path: '*',
            component: () =>
                import ('@/views/pages/page404'),
        }, {
            path: '/',
            redirect: 'dashboard/basic-dashboard',
            name: 'AdminDashboard',
            component: () =>
                import ('@/layouts/Layout'),
            meta: {
                requireAuth: true
            },
            children: [
                // Components
                // {
                //     path: 'dashboard',
                //     name: 'Dashboard',
                //     component: () =>
                //         import ('@/layouts/Layout'),

                // },
                {
                    name: 'Alerts',
                    path: 'pages/alerts',
                    component: () =>
                        import ('@/views/pages/Alerts'),
                },

                {
                    name: 'Profile',
                    path: 'pages/profile',
                    component: () =>
                        import ('@/views/pages/Profile'),
                },

                {
                    name: 'Icons',
                    path: 'pages/icons',
                    component: () =>
                        import ('@/views/pages/Icons'),
                },

                {
                    name: 'TableSimple',
                    path: 'pages/tables-simple',
                    component: () =>
                        import ('@/views/pages/TableSimple'),
                },

                {
                    name: 'Dashboard',
                    path: 'dashboard/basic-dashboard',
                    component: () =>
                        import ('@/views/dashboard/BasicDashboard'),

                },
                {
                    name: 'user',
                    path: 'users/user',
                    component: () =>
                        import ('@/views/users/user'),
                },
                {
                    name: 'seller',
                    path: 'sellers/seller',
                    component: () =>
                        import ('@/views/sellers/seller'),
                },
                {
                    name: 'sellerDetails',
                    path: 'sellers/sellerDetails/:sellerid',
                    props: true,
                    component: () =>
                        import ('@/views/sellers/sellerDetails'),
                },
                {
                    name: 'editSeller',
                    path: 'sellers/editSeller/:sellerid',
                    props: true,
                    component: () =>
                        import ('@/views/sellers/editSeller'),
                },
                {
                    name: 'pendingSellers',
                    path: '/pendingSellers',
                    component: () =>
                        import ('@/views/sellers/pendingSellers'),
                },
                {
                    name: 'pendingSellerDetails',
                    path: '/pendingSellerDetails/:sellerid',
                    props: true,
                    component: () =>
                        import ('@/views/sellers/pendingSellerDetails'),
                },
                {
                    name: 'removeSellers',
                    path: '/removeSellers',
                    component: () =>
                        import ('@/views/sellers/removeSellers'),
                },
                {
                    name: 'removeSellerDetails',
                    path: '/removeSellerDetails/:sellerid',
                    props: true,
                    component: () =>
                        import ('@/views/sellers/removeSellerDetails'),
                },
                {
                    name: 'stylePartners',
                    path: '/stylePartners',
                    component: () =>
                        import ('@/views/StylePartners/stylePartners'),
                },
                {
                    name: 'pendingStylePartners',
                    path: '/pendingStylePartners',
                    component: () =>
                        import ('@/views/StylePartners/pendingStylePartners'),
                },
                {
                    name: 'removedStylePartners',
                    path: '/removedStylePartners',
                    component: () =>
                        import ('@/views/StylePartners/removedStylePartners'),
                },
                {
                    name: 'pendingAppointment',
                    path: '/pendingAppointment',
                    component: () =>
                        import ('@/views/StylePartners/pendingAppointment'),
                },
                {
                    name: 'activeAppointment',
                    path: '/activeAppointment',
                    component: () =>
                        import ('@/views/StylePartners/activeAppointment'),
                },
                {
                    name: 'stylePartnerDetails',
                    path: '/stylePartnerDetails',
                    component: () =>
                        import ('@/views/StylePartners/stylePartnerDetails'),
                },
                {
                    name: 'editStylePartners',
                    path: '/editStylePartners',
                    component: () =>
                        import ('@/views/StylePartners/editStylePartners'),
                },
                {
                    name: 'admin',
                    path: 'admins/admin',
                    component: () =>
                        import ('@/views/admins/admin'),
                },
                {
                    name: 'adminDetails',
                    path: 'admins/adminDetails/:adminid',
                    props: true,
                    component: () =>
                        import ('@/views/admins/adminDetails'),
                },
                {
                    name: 'editAdmin',
                    path: 'admins/editAdmin/:adminid',
                    props: true,
                    component: () =>
                        import ('@/views/admins/editAdmin'),
                },
                {
                    name: 'userDetails',
                    path: 'users/userDetails/:userid',
                    props: true,
                    component: () =>
                        import ('@/views/users/userDetails'),
                },
                {
                    name: 'edit',
                    path: 'users/edit/:userid',
                    props: true,
                    component: () =>
                        import ('@/views/users/edit'),
                },
                {
                    name: 'profile',
                    path: 'settingsAdmin/profile/',

                    component: () =>
                        import ('@/views/settingsAdmin/profile'),

                },
                {
                    name: 'editProfile',
                    path: 'settingsAdmin/editProfile/:adminid',
                    props: true,
                    component: () =>
                        import ('@/views/settingsAdmin/editProfile'),

                },
                {
                    name: 'changePassword',
                    path: '/changePassword',
                    component: () =>
                        import ('@/views/settingsAdmin/changePassword'),

                },
                {
                    name: 'subCategory',
                    path: '/subCategory',
                    component: () =>
                        import ('@/views/subCategory/subCategory'),

                },
                {
                    name: 'categories',
                    path: '/category/categories',
                    component: () =>
                        import ('@/views/category/categories'),

                },
                {
                    name: 'shopByLookCategory',
                    path: '/shopByLookCategory',
                    component: () =>
                        import ('@/views/ShopByLook/shopByLookCategory'),

                },
                {
                    name: 'shopByLookTheme',
                    path: '/shopByLookTheme',
                    component: () =>
                        import ('@/views/ShopByLook/shopByLookTheme'),

                },
                {
                    name: 'colorCombination',
                    path: '/colorCombination',
                    component: () =>
                        import ('@/views/ShopByLook/colorCombination'),

                },
                {
                    name: 'bodyType',
                    path: '/bodyType',
                    component: () =>
                        import ('@/views/ShopByLook/bodyType'),

                },
                {
                    name: 'discoverList',
                    path: '/discoverList',
                    component: () =>
                        import ('@/views/ShopByLook/discoverList'),

                },
                {
                    name: 'discoverDetails',
                    path: '/discoverDetails',
                    component: () =>
                        import ('@/views/ShopByLook/discoverDetails'),

                },
                {
                    name: 'stylCategories',
                    path: '/stylCategory/stylCategories',
                    component: () =>
                        import ('@/views/stylCategory/stylCategories'),

                },
                {
                    name: 'blogCategories',
                    path: '/blogCategories',
                    component: () =>
                        import ('@/views/BlogCategories/blogCategories'),

                },
                {
                    name: 'subCategoryDetails',
                    path: '/subCategory/subCategoryDetails/:subid',
                    props: true,
                    component: () =>
                        import ('@/views/subCategory/subCategoryDetails'),

                },
                {
                    name: 'products',
                    path: '/sellerProducts/products/:sellerId',
                    props: true,
                    component: () =>
                        import ('@/views/sellerProducts/products'),

                },
                {
                    name: 'ProductTags',
                    path: '/productTags',
                    props: true,
                    component: () =>
                        import ('@/views/sellerProducts/ProductTags/productTags'),

                },
                {
                    name: 'pendingProducts',
                    path: '/pendingProducts',

                    component: () =>
                        import ('@/views/sellerProducts/pendingProducts'),

                },
                {
                    name: 'lookingFor',
                    path: '/Guest/lookingFor',

                    component: () =>
                        import ('@/views/Guest/lookingFor'),

                },
                {
                    name: 'subscribe',
                    path: '/Subscribe/subscribe',

                    component: () =>
                        import ('@/views/Subscribe/subscribe'),

                },
                {
                    name: 'businessQuery',
                    path: '/Business/businessQuery',

                    component: () =>
                        import ('@/views/Business/businessQuery'),

                },
                {
                    name: 'LandingPage_Queries',
                    path: '/landingPage/queries',

                    component: () =>
                        import ('@/views/LandingPage/queries'),

                },
                {
                    name: 'LandingPage',
                    path: '/landingPage/landingPage',

                    component: () =>
                        import ('@/views/LandingPage/landingPage'),

                },
                {
                    name: 'Dynamic_Image_Links',
                    path: '/dynamicLinks',

                    component: () =>
                        import ('@/views/DynamicLinks/dynamicLinks'),

                },
                {
                    name: 'deal',
                    path: '/Deal/deal',

                    component: () =>
                        import ('@/views/Deal/deal'),

                },
                {
                    name: 'approvedDeals',
                    path: '/Deal/approvedDeals',

                    component: () =>
                        import ('@/views/Deal/approvedDeals'),

                },
                {
                    name: 'pendingProductDetails',
                    path: '/pendingProductDetails/:productid',
                    props: true,
                    component: () =>
                        import ('@/views/sellerProducts/pendingProductDetails'),

                },
                {
                    name: 'productDetails',
                    path: '/productDetails/:productid',
                    props: true,
                    component: () =>
                        import ('@/views/sellerProducts/productDetails'),

                },
                {
                    name: 'productEdit',
                    path: '/productEdit/:productid',
                    props: true,
                    component: () =>
                        import ('@/views/sellerProducts/productEdit'),

                },
                {
                    name: 'stockAddrer',
                    path: '/stockAddrer',
                    props: true,
                    component: () =>
                        import ('@/views/sellerProducts/stockAddrer'),

                },
                {
                    name: 'pendingProductEdit',
                    path: '/pendingProductEdit/:productid',
                    props: true,
                    component: () =>
                        import ('@/views/sellerProducts/pendingProductEdit'),

                },
                {
                    name: 'editProduct',
                    path: '/editProduct/:productid',
                    props: true,
                    component: () =>
                        import ('@/views/sellerProducts/editProduct'),

                },
                {
                    name: "ratingList",
                    path: "/rating/ratingList",
                    component: () =>
                        import ('@/views/rating/ratingList'),
                },
                {
                    name: "ratingDetails",
                    path: "/ratingDetails/:rateid",
                    props: true,
                    component: () =>
                        import ('@/views/rating/ratingDetails'),
                },
                {
                    name: "userReview",
                    path: "/UserReview/userReview",
                    component: () =>
                        import ('@/views/UserReview/userReview'),
                },
                {
                    name: "userReviewDetails",
                    path: "/usereviewDetails/:userid",
                    props: true,
                    component: () =>
                        import ('@/views/UserReview/userReviewDetails'),
                },
                {
                    name: "settingsList",
                    path: "settings/settingsList",
                    component: () =>
                        import ('@/views/settings/settingsList'),
                },
                {
                    name: "sliderList",
                    path: "home/sliderList",
                    component: () =>
                        import ('@/views/home/sliderList'),
                },
                {
                    name: "sliderDeleted",
                    path: "sliderDeleted",
                    component: () =>
                        import ('@/views/home/sliderDeleted'),
                },
                {
                    name: "exclusiveList",
                    path: "home/exclusiveList",
                    component: () =>
                        import ('@/views/home/exclusiveList'),
                },
                {
                    name: "exclusiveDeleted",
                    path: "exclusiveDeleted",
                    component: () =>
                        import ('@/views/home/exclusiveDeleted'),
                },
                {
                    name: "trendingList",
                    path: "home/trendingList",
                    component: () =>
                        import ('@/views/home/trendingList'),
                },
                {
                    name: "trendingDeleted",
                    path: "trendingDeleted",
                    component: () =>
                        import ('@/views/home/trendingDeleted'),
                },
                {
                    name: "trendingSlider",
                    path: "home/trendingSlider",
                    component: () =>
                        import ('@/views/home/trendingSlider'),
                },
                {
                    name: "trendingSliderDeleted",
                    path: "trendingSliderDeleted",
                    component: () =>
                        import ('@/views/home/trendingSliderDeleted'),
                },
                {
                    name: "trendingEdit",
                    path: "trendingEdit/:trendingid",
                    props: true,
                    component: () =>
                        import ('@/views/home/trendingEdit'),
                },
                {
                    name: "trendingDetails",
                    path: "trendingDetals/:trendingid",
                    props: true,
                    component: () =>
                        import ('@/views/home/trendingDetails'),
                },
                {
                    name: "blog",
                    path: "home/blog",
                    component: () =>
                        import ('@/views/home/blog'),
                },
                {
                    name: "blogDeleted",
                    path: "blogDeleted",
                    component: () =>
                        import ('@/views/home/blogDeleted'),
                },
                {
                    name: "collectionList",
                    path: "home/collectionList",
                    component: () =>
                        import ('@/views/home/collectionList'),
                },
                {
                    name: "mainCollection",
                    path: "home/mainCollection",
                    component: () =>
                        import ('@/views/home/mainCollection'),
                },
                {
                    name: "advertisementList",
                    path: "home/advertisementList",
                    component: () =>
                        import ('@/views/home/advertisementList'),
                },
                {
                    name: "houseofBeauty",
                    path: "home/houseofBeauty",
                    component: () =>
                        import ('@/views/home/houseofBeauty'),
                },
                {
                    name: "loginBackground",
                    path: "home/loginBackground",
                    component: () =>
                        import ('@/views/home/loginBackground'),
                },
                {
                    name: "lookingFor",
                    path: "home/lookingFor",
                    component: () =>
                        import ('@/views/home/lookingFor'),
                },
                {
                    name: "paymentReport",
                    path: "/paymentReport",
                    component: () =>
                        import ('@/views/Payments/payReport'),
                },
                {
                    name: "purchaseList",
                    path: "purchases/purchaseList",
                    component: () =>
                        import ('@/views/purchases/purchaseList'),
                },
                {
                    name: "purchaseDetails",
                    path: "purchaseDetails/:purchaseid",
                    props: true,
                    component: () =>
                        import ('@/views/purchases/purchaseDetails'),
                },
                {
                    name: "pendingOffer",
                    path: "/pendingOffer",
                    component: () =>
                        import ('@/views/Offer/pendingOffer'),
                }, {
                    name: "offer",
                    path: "/offer",
                    component: () =>
                        import ('@/views/Offer/offer'),
                },
                {
                    name: "productView",
                    path: "Products/productView",
                    component: () =>
                        import ('@/views/Products/productView'),
                },
                {
                    path: 'staticComponent/:Name',
                    name: 'staticComponent',
                    component: () =>
                        import ('@/views/Static/staticComponent'),
                    props: true,
                },
                {
                    path: 'staticStylook/:Name',
                    name: 'staticStylook',
                    component: () =>
                        import ('@/views/Static/staticStylook'),
                    props: true,
                },
                {
                    path: 'staticFooter/:Name',
                    name: 'staticFooter',
                    component: () =>
                        import ('@/views/Static/staticFooter'),
                    props: true,
                },
                {
                    path: 'promoCode',
                    name: 'PromoCode',
                    component: () =>
                        import ('@/views/Static/promoCode'),

                },
                {
                    path: 'newPromocode',
                    name: 'newPromocode',
                    component: () =>
                        import ('@/views/NewPromocode/newPromocode'),

                },
                {
                    path: 'promocodeReport',
                    name: 'promocodeReport',
                    component: () =>
                        import ('@/views/NewPromocode/promocodeReport'),

                },
                {
                    path: 'sizeChart',
                    name: 'SizeChart',
                    component: () =>
                        import ('@/views/Products/sizeChart'),

                },
                {
                    path: 'pincodes',
                    name: 'pincodes',
                    component: () =>
                        import ('@/views/sellers/pincodes/pincodes'),

                },
                {
                    path: 'zone',
                    name: 'zone',
                    component: () =>
                        import ('@/views/sellers/pincodes/zone'),

                },
                {
                    path: 'newBlog',
                    name: 'newBlog',
                    component: () =>
                        import ('@/views/Static/newBlog'),

                },
                {
                    path: 'blogDetails/:id',
                    name: 'BlogDetails',
                    props: true,
                    component: () =>
                        import ('@/views/Static/blogDetails'),

                },
                {
                    name: "footerHeading",
                    path: "Footer/footerHeading",
                    component: () =>
                        import ('@/views/Footer/footerHeading'),
                },
                {
                    name: "hsnList",
                    path: "hsnList",
                    component: () =>
                        import ('@/views/HSN/hsnList'),
                },
                {
                    name: "brandList",
                    path: "brandList",
                    component: () =>
                        import ('@/views/Brand/brandList'),
                },
                {
                    name: "themeEnquiry",
                    path: "themeEnquiry",
                    component: () =>
                        import ('@/views/ThemeEnquiry/themeEnquiry'),
                },
                {
                    name: "Story Category",
                    path: "storyCategory",
                    component: () =>
                        import ('@/views/Stories/storyCategory'),
                },
                {
                    name: "Stories",
                    path: "stories",
                    component: () =>
                        import ('@/views/Stories/stories'),
                },
                {
                    name: "storyEnquiry",
                    path: "storyEnquiry",
                    component: () =>
                        import ('@/views/Stories/storyEnquiry'),
                },
                {
                    name: "storyHead",
                    path: "storyHead",
                    component: () =>
                        import ('@/views/Stories/storyHead'),
                },
                {
                    name: "commonImage",
                    path: "commonImage",
                    component: () =>
                        import ('@/views/Stories/commonImage'),
                },
                {
                    name: "websiteRequest",
                    path: "websiteRequest",
                    component: () =>
                        import ('@/views/Stories/websiteRequest'),
                },
                {
                    name: "storyQuotesList",
                    path: "storyQuotesList",
                    component: () =>
                        import ('@/views/Stories/storyQuotesList'),
                },
                {
                    name: "Affiliator",
                    path: "affiliator",
                    component: () =>
                        import ('@/views/Affiliator/affiliator'),
                },
                {
                    name: "Pendingaffiliator",
                    path: "pendingAffiliator",
                    component: () =>
                        import ('@/views/Affiliator/pendingAffiliator'),
                },
                {
                    name: "AffiliatorDetails",
                    path: "AffiliatorDetails",
                    component: () =>
                        import ('@/views/Affiliator/AffiliatorDetails'),
                },
                {
                    name: "affiliatorEdit",
                    path: "affiliatorEdit",
                    component: () =>
                        import ('@/views/Affiliator/affiliatorEdit'),
                },
                {
                    name: "pendingTheme",
                    path: "pendingTheme",
                    component: () =>
                        import ('@/views/Affiliator/pendingTheme'),
                },
                {
                    name: "trialRoom",
                    path: "trialRoom",
                    component: () =>
                        import ('@/views/Wishlist/trialRoom'),
                },
                {
                    name: "trialRoomDetails",
                    path: "trialRoomDetails",
                    component: () =>
                        import ('@/views/Wishlist/trialRoomDetails'),
                },
                {
                    name: "wishlistPurpose",
                    path: "wishlistPurpose",
                    component: () =>
                        import ('@/views/Wishlist/wishlistPurpose'),
                },
                {
                    name: "userRespondDetails",
                    path: "userRespondDetails",
                    component: () =>
                        import ('@/views/Wishlist/userRespondDetails'),
                },
                {
                    name: "wishlistPurposeUserRespond",
                    path: "wishlistPurposeUserRespond",
                    component: () =>
                        import ('@/views/Wishlist/wishlistPurposeUserRespond'),
                },

                {
                    name: "wishlistSearch",
                    path: "wishlistSearch",
                    component: () =>
                        import ('@/views/Wishlist/wishlistSearch'),
                },
                {
                    name: "invitation",
                    path: "invitation",
                    component: () =>
                        import ('@/views/Invitation/invitation'),
                },
                {
                    name: "invitationFestival",
                    path: "invitationFestival",
                    component: () =>
                        import ('@/views/Invitation/invitationFestival'),
                },
                {
                    name: "invitationDetails",
                    path: "invitationDetails",
                    component: () =>
                        import ('@/views/Invitation/invitationDetails'),
                },
                {
                    name: "invitationFestivalDetails",
                    path: "invitationFestivalDetails",
                    component: () =>
                        import ('@/views/Invitation/invitationFestivalDetails'),
                },
                {
                    name: "invitationCategory",
                    path: "invitationCategory",
                    component: () =>
                        import ('@/views/Invitation/invitationCategory'),
                },
                {
                    name: "invitationCategoryView",
                    path: "invitationCategoryView",
                    component: () =>
                        import ('@/views/Invitation/invitationCategoryView'),
                },
                {
                    name: "referFriendsList",
                    path: "referFriendsList",
                    component: () =>
                        import ('@/views/Invitation/referFriendsList'),
                },
                {
                    name: "tag",
                    path: "tag",
                    component: () =>
                        import ('@/views/Tags/tag'),
                },
                {
                    name: "influencerTags",
                    path: "influencerTags",
                    component: () =>
                        import ('@/views/Tags/influencerTags'),
                },
                {
                    name: "keywords",
                    path: "keywords",
                    component: () =>
                        import ('@/views/StylePartners/keywords'),
                },
                {
                    name: "voucherList",
                    path: "voucherList",
                    component: () =>
                        import ('@/views/Voucher/voucherList'),
                },
                {
                    name: "superCoins",
                    path: "superCoins",
                    component: () =>
                        import ('@/views/newSettings/superCoins/superCoins'),
                },
                {
                    name: "coinsReport",
                    path: "coinsReport",
                    component: () =>
                        import ('@/views/newSettings/superCoins/coinsReport'),
                },
                {
                    name: "coinRange",
                    path: "coinRange",
                    component: () =>
                        import ('@/views/newSettings/superCoins/coinRange'),
                },
                {
                    name: "coinRangeOffline",
                    path: "coinRangeOffline",
                    component: () =>
                        import ('@/views/newSettings/superCoins/coinRangeOffline'),
                },
                {
                    name: "pendingBills",
                    path: "pendingBills",
                    component: () =>
                        import ('@/views/newSettings/superCoins/pendingBills'),
                },
                {
                    name: "activeBills",
                    path: "activeBills",
                    component: () =>
                        import ('@/views/newSettings/superCoins/activeBills'),
                },
                {
                    name: "referalRange",
                    path: "referalRange",
                    component: () =>
                        import ('@/views/newSettings/Referal/referalRange'),
                },
                {
                    name: "commission",
                    path: "commission",
                    component: () =>
                        import ('@/views/newSettings/AffiliationCommission/commission'),
                },
                {
                    name: "affiliationReport",
                    path: "affiliationReport",
                    component: () =>
                        import ('@/views/Affiliator/affiliationReport'),
                },
                {
                    name: "affiliationReportSP",
                    path: "affiliationReportSP",
                    component: () =>
                        import ('@/views/Affiliator/affiliationReportSP'),
                },
                {
                    name: "affiliationView",
                    path: "affiliationView",
                    component: () =>
                        import ('@/views/Affiliator/affiliationView'),
                },
                {
                    name: "ominiStore",
                    path: "ominiStore",
                    component: () =>
                        import ('@/views/OminiStore/ominiStore'),
                },
                {
                    name: "ominiStoreDetails",
                    path: "ominiStoreDetails",
                    component: () =>
                        import ('@/views/OminiStore/ominiStoreDetails'),
                },
            ]
        },

    ],
})
router.beforeEach((to, from, next) => {
    if (to.matched.some(route => route.meta.requireAuth && store.state.isLoggedIn == false)) {
        next({
            path: '/auth/login',
            params: { lastPage: from }
        })
        return
    }
    if (to.matched.some(route => route.name && store.state.isLoggedIn)) {
        axios({
                method: 'POST',
                url: '/validate/token',
                headers: {
                    'x-auth-token': localStorage.getItem('token')
                }
            }).then(response => {
                if (!response.data.status) {
                    store.commit("sessionOut", true)
                    return
                }
            })
            .catch(err => {
                this.msg = err;
            });
    }
    if ((from.name == 'productView') && (to.name != 'productDetails') && (to.name != 'productEdit')) {
        localStorage.removeItem('sStyl')
        localStorage.removeItem('sSeller')
        localStorage.removeItem('sCategory')
        localStorage.removeItem('sSubCategory')
    }
    if ((from.name == 'productDetails') && (to.name != 'productView') && (to.name != 'productEdit')) {
        localStorage.removeItem('sStyl')
        localStorage.removeItem('sSeller')
        localStorage.removeItem('sCategory')
        localStorage.removeItem('sSubCategory')
    }
    if ((from.name != 'productDetails') && (to.name != 'productView') && (to.name == 'productEdit')) {
        localStorage.removeItem('sStyl')
        localStorage.removeItem('sSeller')
        localStorage.removeItem('sCategory')
        localStorage.removeItem('sSubCategory')
    }
    if ((to.name == 'applogin') && store.state.isLoggedIn == true) {
        next({ name: 'AdminDashboard' })
        return
    }
    next()
})

export default router