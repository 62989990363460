import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import './plugins/base'
import axios from 'axios'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
Vue.use(Viewer)
import OtpInput from "@bachdgvn/vue-otp-input";
Vue.component("v-otp-input", OtpInput);
import ImageMagnifier from 'vue-image-magnifier'
Vue.use(ImageMagnifier)

// import VueCropper from 'vue-cropperjs';
// import 'cropperjs/dist/cropper.css';
// import 'vue-advanced-cropper/dist/style.css';

// Vue.component(VueCropper);
// import VueTelInput from 'vue-tel-input'
// Vue.use(VueTelInput)



import OwlCarousel from 'vue-owl-carousel'

Vue.component('OwlCarousel', OwlCarousel)

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

Vue.component('apexchart', VueApexCharts)
import VueElementLoading from 'vue-element-loading'
Vue.component('VueElementLoading', VueElementLoading)

import ServerError from './views/pages/page500'
Vue.component('ServerError', ServerError)

Vue.config.productionTip = false

Window.axios = require('axios');
// Vue.use(axios)
// Vue.prototype.baseURL = "https://api.styloop.in/u/";

Vue.prototype.baseURL = "https://api.styloop.in/wp/"
Vue.prototype.mediaURL = "https://api.styloop.in/u/";
Vue.prototype.mediaUURL = "https://api.styloop.in/file/get/";
Vue.prototype.ipURL = "https://api.styloop.in";
axios.defaults.baseURL = "https://api.styloop.in";

// Vue.prototype.baseURL = "http://192.168.48.235:3200/wp/"
// Vue.prototype.mediaURL = "http://192.168.48.235:3200/u/";
// Vue.prototype.mediaUURL = "http://192.168.52.150:3200/file/get/";
// Vue.prototype.ipURL = "http://192.168.49.80:3200";
// axios.defaults.baseURL = "http://192.168.49.80:3200";

// axios.defaults.baseURL = 'http://localhost:3200';
// Vue.prototype.baseURL = 'http://localhost::3200';

// Vue.prototype.baseURL = "http://13.232.188.153/wp/"
new Vue({
    vuetify,
    store,
    router,
    render: h => h(App)
}).$mount('#app')