import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        Sidebar_drawer: null,
        Customizer_drawer: false,
        SidebarColor: '#171515',
        SidebarBg: '',
        profilePic: null,
        completeUserData: {},
        productPageFilters: {},
        isLoggedIn: !!localStorage.getItem('isLoggedIn'),
        userRole: localStorage.getItem('userRole'),
        userName: localStorage.getItem('userName'),
        isAdmin: !!localStorage.getItem('isAdmin'),
        isSubAdmin: !!localStorage.getItem('isSubAdmin'),
        currentPage: null,
        currentPage1: null,
    },
    mutations: {
        SET_SIDEBAR_DRAWER(state, payload) {
            state.Sidebar_drawer = payload
        },
        SET_CUSTOMIZER_DRAWER(state, payload) {
            state.Customizer_drawer = payload
        },
        SET_SIDEBAR_COLOR(state, payload) {
            state.SidebarColor = payload
        },
        loginUser(state) {
            state.isLoggedIn = true
            localStorage.setItem('isLoggedIn', true)
        },
        logoutUser(state) {
            state.isLoggedIn = false
            state.isAdmin = false
            state.isSubAdmin = false
            localStorage.removeItem('isLoggedIn');
            localStorage.removeItem("token");
            localStorage.removeItem('isAdmin')
            localStorage.removeItem('isSubAdmin')
            localStorage.removeItem("userRole");
            localStorage.removeItem("userName");
            state.isLoggedIn = "";
        },
        userRole(state, item) {
            state.userRole = item
            if (item == "Admin") {
                state.isAdmin = true
                localStorage.setItem('isAdmin', true)
            } else if (item == "SubAdmin") {
                state.isSubAdmin = true
                localStorage.setItem('isSubAdmin', true)
            }
            localStorage.setItem("userRole", item);
        },
        userName(state, item) {
            state.userName = item
            localStorage.setItem("userName", item);
        },
        userData(state, payload) {
            state.profilePic = payload.image
            state.completeUserData = payload
        },
        changeCurrentPage(state, item) {
            state.currentPage = item
        },
        changePendCurrentPage(state, item) {
            state.currentPage1 = item
        },
        productPageFilters(state, item) {
            if (item.category)
                localStorage.setItem("sCategory", item.category)
            else
                localStorage.removeItem("sCategory")
            if (item.subcategory)
                localStorage.setItem("sSubCategory", item.subcategory)
            else

                localStorage.removeItem("sSubCategory")
            if (item.sellerid)
                localStorage.setItem("sSeller", item.sellerid)
            else

                localStorage.removeItem("sSeller")
            if (item.omniId)
                localStorage.setItem("sOmni", item.omniId)
            else

                localStorage.removeItem("sOmni")
            if (item.styl)
                localStorage.setItem("sStyl", item.styl)
            else
                localStorage.removeItem("sStyl")
            state.productPageFilters = item
        },

        sessionOut(state) {
            localStorage.removeItem("token");
            localStorage.removeItem('isLoggedIn');
            state.currentPage = null
            state.isLoggedIn = false
            router.push('/auth/login')
        }
    },
    actions: {

    }
})