<template>
  <v-app
    id="materialpro"
    :class="`${
      !$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'
    }`"
  >
    <router-view />
  </v-app>
</template>
<script>
import axios from "axios";
import store from "./store";
export default {
  name: "App",
  data() {
    return {};
  },
  beforeMount() {
    if (typeof localStorage.getItem("token") == "string") {
      axios({
        method: "GET",
        url: "/user/profile",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            store.commit("userData", response.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
};
</script>
<style >
/* width */
::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey !important;
  border-radius: 5px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #919191 !important; */
  background: black !important;
  border-radius: 5px !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
